<script setup lang="ts">
import { useAuthStore } from '~/stores/auth/useAuthStore';
import { ROUTES } from '~/constants/routes';
const { t } = useI18n();

useHead({ title: t("routes.main") });
definePageMeta({
    layout: 'private',
    middleware: ['auth']
});

const authStore = useAuthStore();
const { userId } = storeToRefs(authStore);

onMounted(() => {
    navigateTo(ROUTES.user(userId.value));
});

</script>

<template>
    <v-skeleton-loader
        color="#fafafa"
        class="loader"
        type="article, text, button, paragraph, article, paragraph, button"
    />
</template>
